import { Slot } from '@radix-ui/react-slot'
import { type VariantProps } from 'class-variance-authority'
import type { LottieRefCurrentProps } from 'lottie-react'
import * as React from 'react'

import { cnva } from '~/utils/cn'

import type { LottieComponent } from '../Icons/Lotties'
import { Spinner } from '../Spinner'
import { Slottable } from '../utils/Slottable'

export const buttonStyle = cnva(
  'inline-flex items-center justify-center whitespace-nowrap font-medium leading-none outline-none transition-all disabled:pointer-events-none disabled:opacity-48',
  {
    variants: {
      variant: {
        primary:
          'bg-accent-primary text-accent-foreground hover:bg-accent-secondary active:bg-accent-tertiary',
        blur: 'bg-under/64 text-highlight backdrop-blur-2xl hover:bg-under/80 active:bg-under',
        outline:
          'border border-border/8 text-foreground hover:bg-under/4 active:bg-under/6',
        'outline-filled':
          'border border-border/8 bg-under/4 text-foreground hover:bg-under/6 active:bg-under/8',
        ghost: 'text-foreground hover:bg-under/4 active:bg-under/6',
        'over-pop-up': 'text-pop-up-fg hover:bg-under/4 active:bg-under/6',
        tag: 'bg-foreground/4 text-foreground hover:bg-foreground/6 active:bg-foreground/8',
      },
      size: {
        lg: 'h-12 gap-2 rounded-md px-6 py-4 text-sm',
        md: 'h-9 gap-1.5 rounded-md px-3.5 py-2.5 text-xs',
        sm: 'h-8 gap-1 rounded-md px-2.5 py-2 text-xs',
        xs: 'h-4.5 gap-1 rounded-xs px-1.5 py-0.5 text-2xs',
      },
      icon: {
        left: '[&>.button-icon]:-ml-0.5', // [&>.button-icon]:opacity-64
        right: '[&>.button-icon]:-mr-0.5', // [&>.button-icon]:opacity-64
        solo: '',
      },
    },
    compoundVariants: [
      { icon: 'solo', size: 'xs', className: 'w-4.5 px-0 py-0' },
      { icon: 'solo', size: 'sm', className: 'w-8 px-0 py-0' },
      { icon: 'solo', size: 'md', className: 'w-9 px-0 py-0' },
      { icon: 'solo', size: 'lg', className: 'w-14 px-0 py-0' },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    Omit<VariantProps<typeof buttonStyle>, 'icon'> {
  asChild?: boolean
  icon?:
    | React.ForwardRefExoticComponent<
        Omit<React.SVGProps<SVGSVGElement>, 'ref'> &
          React.RefAttributes<SVGSVGElement>
      >
    | LottieComponent
  iconPosition?: 'left' | 'right'
  iconOpacity?: number
  isLoading?: boolean
}

const buttonToSpinnerSizeMap = {
  xs: '2xs',
  sm: 'xs',
  md: 'sm',
  lg: 'md',
} as const

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      variant,
      size,
      asChild = false,
      icon,
      iconPosition = 'left',
      iconOpacity,
      isLoading,
      onMouseEnter,
      onMouseLeave,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'

    const [isHovered, setIsHovered] = React.useState(false)

    // When asChild is true, we need to check if direct child has children
    const hasChild = !asChild
      ? Boolean(children)
      : Boolean(React.isValidElement(children) && children.props?.children)

    return (
      <Comp
        className={buttonStyle({
          variant,
          size,
          icon: icon && !hasChild ? 'solo' : iconPosition,
          className: [
            className,
            isLoading && !icon && '[&>*:not(.button-spinner)]:invisible',
          ],
        })}
        ref={ref}
        onMouseEnter={(evt: React.MouseEvent<HTMLButtonElement>) => {
          setIsHovered(true)
          onMouseEnter?.(evt)
        }}
        onMouseLeave={(evt: React.MouseEvent<HTMLButtonElement>) => {
          setIsHovered(false)
          onMouseLeave?.(evt)
        }}
        {...props}
      >
        <Slottable asChild={asChild} child={children}>
          {(child) => (
            <>
              {icon && iconPosition === 'left' && (
                <ButtonIcon
                  icon={icon}
                  iconOpacity={iconOpacity}
                  isLoading={isLoading}
                  size={size}
                  isHovered={isHovered}
                />
              )}
              {typeof child === 'string' ? <span>{child}</span> : child}
              {icon && iconPosition === 'right' && (
                <ButtonIcon
                  icon={icon}
                  iconOpacity={iconOpacity}
                  isLoading={isLoading}
                  size={size}
                  isHovered={isHovered}
                />
              )}
              {!icon && isLoading && (
                <ButtonSpinner
                  size={size}
                  className="button-spinner absolute"
                />
              )}
            </>
          )}
        </Slottable>
      </Comp>
    )
  }
)
Button.displayName = 'Button'

const ButtonSpinner = ({
  size,
  className,
}: {
  size: ButtonProps['size']
  className?: string
}) => (
  <Spinner className={className} size={buttonToSpinnerSizeMap[size || 'md']} />
)

type ButtonIconProps = Pick<ButtonProps, 'size' | 'isLoading'> & {
  icon: NonNullable<ButtonProps['icon']>
  isHovered?: boolean
  iconOpacity?: number
}

const buttonIconStyle = cnva(
  'relative flex items-center justify-center text-[1.25em]',
  {
    variants: {
      size: {
        lg: 'h-6 w-6',
        md: 'h-5 w-5',
        sm: 'h-4 w-4',
        xs: 'h-3 w-3 text-xs',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }
)

const ButtonIcon = ({
  size,
  isLoading,
  icon: IconComponent,
  isHovered,
  iconOpacity = 64,
}: ButtonIconProps) => {
  const iconRef = React.useRef<SVGSVGElement | LottieRefCurrentProps>(null)

  React.useEffect(
    function animateLottie() {
      if (!iconRef.current || iconRef.current instanceof SVGSVGElement) return

      if (isHovered) iconRef.current.setDirection(1)
      else iconRef.current.setDirection(-1)

      iconRef.current.play()
    },
    [iconRef, isHovered]
  )

  return (
    <span
      className={buttonIconStyle({
        size,
        className: `button-icon opacity-${iconOpacity}`,
      })}
    >
      {isLoading ? (
        <ButtonSpinner size={size} className="absolute" />
      ) : (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <IconComponent ref={iconRef as any} />
      )}
    </span>
  )
}
